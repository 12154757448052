<br>
<br>
<mat-slide-toggle (change)="teamToggle($event)" id="1"
    [checked]="true" >
    1st XI
</mat-slide-toggle>
<mat-slide-toggle (change)="teamToggle($event)" id="2"
    [checked]="true" >
    2nd XI
</mat-slide-toggle>
<mat-slide-toggle (change)="teamToggle($event)" id="S"
    [checked]="true" >
    Sunday XI
</mat-slide-toggle>
<br>
<!-- Display Google Map -->
<agm-map [zoom]="zoom" [draggable]="draggable" [fitBounds]="true">
    <agm-marker *ngFor='let m of displayMarkers; let i = index' [latitude]='m.lat' [longitude]='m.lng' [label]='m.label'
        [markerDraggable]='m.draggable' [iconUrl]='m.iconUrl' [agmFitBounds]="true">
        <agm-info-window [disableAutoPan]="true">
            <ul style="list-style-type:none">
                <li *ngFor='let adrs of m.infowintxt'>{{adrs.address}}</li>
            </ul>
        </agm-info-window>
    </agm-marker>
</agm-map>