import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'ncc-app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class BannerComponent {

 
 // implements OnInit
 //ngOnInit(): void { }

}
