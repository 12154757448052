<mat-card *ngIf="photoSelected">
  <mat-card-header>
    <mat-card-subtitle>{{photoSelected.caption}}</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <img src="{{photoSelected.url}}" height="100%" width="100%">
  </mat-card-content>
  <mat-card-actions>
      <button mat-button (click)="closeSelectedPhoto()">Close</button>
    </mat-card-actions>
</mat-card>


<mat-grid-list cols="3" rowHeight="1:1">
  <mat-grid-tile *ngFor="let photo of photoList | async">
    <mat-card (click)="setSelectedPhoto(photo)">
      <mat-card-header>
        <mat-card-subtitle>{{photo.caption}}</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <ng-container *ngIf="photo.url_thumb != null; then dispURLThumb; else dispURL">
            
        </ng-container>
        <ng-template #dispURLThumb>
          <img [src]="photo.url_thumb" height="100%" width="100%" crossorigin="anonymous">
        </ng-template>
        <ng-template #dispURL>
          <img src="{{photo.url}}" height="200px" width="200px" >
        </ng-template>
      </mat-card-content>
    </mat-card>
  </mat-grid-tile>
</mat-grid-list>
