<div class="row">
		<img class='splash_img' src='/assets/img/NCCCrest.png'>
		<p></p>
		<p class='left_panel_heading'>Navestock Cricket Club</p>
		<p class='left_panel_heading'>250th Anniversary</p>
		<br>
		<p class="left_panel_heading2">Thanks to all who supported our Anniversary Cricket Festival.</p>
		<p class="left_panel_heading2">Your help and support throughout the week did not go unnoticed</p>
		<p class="left_panel_heading2">and we hope everyone enjoyed the celebrations!</p>
		<table class='event_table'>
			<tr>
				<td class="event_col">
					<a [routerLink]="['../galadinner']">
						<div>
							<b>Monday 18th June</b>
						</div>
						<div>Anniversary Cricket Dinner with Graham Gooch</div>
						<div>(Old Brentwood Club, 8pm)</div>
					</a>
				</td>
				<td class="event_col">
					<div>
						<b>Friday 22nd June</b>
					</div>
					<div>Club Quiz Night</div>
					<div>(Navestock Cricket Club, 8pm start)</div>
				</td>
			</tr>
			<tr>
				<td class="event_col">
					<a [routerLink]="['../veterans7aside']">
						<div>
							<b>Tuesday 19th June</b>
						</div>
						<div>Veterans 7-a-side Tournament</div>
						<div>(Navestock Cricket Club, 1pm start)</div>
					</a>
				</td>
				<td class="event_col">
					<a [routerLink]="['/matchdetails/3547983']">
						<div>
							<b>Saturday 23rd June</b>
						</div>
						<div>T. Rippon League Fixture</div>
						<div>(NCC 1XI vs South Woodham Ferrers 1XI)</div>
					</a>
				</td>
			</tr>
			<tr>
				<td class="event_col">
					<a [routerLink]="['../colinbridgevillagecup']">
						<div>
							<b>Wednesday 20th June</b>
						</div>
						<div>The Colin Bridge Villagers Cup</div>
						<div>(Navestock Cricket Club, 5pm start)</div>
					</a>
				</td>
				<td class="event_col">
					<a [routerLink]="['/matchdetails/3550984']">
						<div>
							<b>Saturday 23rd June</b>
						</div>
						<div>T. Rippon League Fixture</div>
						<div>(NCC 2XI vs Writtle 3XI)</div>
					</a>

				</td>
			</tr>
			<tr>
				<td class="event_col">
					<a [routerLink]="['/matchdetails/3563693']">
						<div>
							<b>Thursday 21th June</b>
						</div>
						<div>Navestock XI vs Essex Seniors</div>
						<div>(Navestock Cricket Club, 1pm start)</div>
					</a>
				</td>
				<td class="event_col">
					<a [routerLink]="['/matchdetails/3554510']">
						<div>
							<b>Sunday 24th June</b>
						</div>
						<div>Navestock Presidents Day</div>
						<div>Navestock XI vs Navestock Presidents XI</div>
						<div>(Navestock Cricket Club, 12pm start)</div>
					</a>
				</td>
			</tr>
		</table>
	<div class="endOfPage"></div>
</div>
