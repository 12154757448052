<div class='right_panel_widgets green'>
	<div class='panel_widget_menu green'>
		<h2>Fixtures</h2>
	</div>
    <div *ngFor="let t of matchWidgetData | async | matchwidgetdata">
        <mat-card>
            <mat-card-header>
                <mat-card-title>{{t.teamName}}</mat-card-title>
                <mat-card-subtitle></mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <div *ngFor="let f of t.matchList ">
                    <a [routerLink]="['/match/matchdetails', f.id]" >
                        <div>{{f.match_date.toDate() | date:'d MMM yyyy'}} @ {{f.match_time}}</div>
                        <div>{{f.opposition_club_name}}</div>
                    </a>
                    <mat-divider></mat-divider>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>
<br>
<br>