import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ncc-app-colin-bridge-village-cup',
  templateUrl: './colin-bridge-village-cup.component.html',
  styleUrls: ['./colin-bridge-village-cup.component.scss']
})
export class ColinBridgeVillageCupComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
