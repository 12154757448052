<div class="row">
  <img class='splash_img' src='/assets/img/NCCCrest.png'>
  <p></p>
  <p class='left_panel_heading'>Navestock Cricket Club</p>
  <p class='left_panel_heading'>Veterans 7-a-side Tournament</p>
  <p class="left_panel_heading2">Tuesday 19th June 2018</p>

  
  <p class="history_text">A fantastic afternoon of cricket yesterday afternoon the saw the Navestock Saturday side beat a South Weald side in the Over 50's 7-a-side tournament!</p>
  <br>
  <img class='naveOldPic_img' src='/assets/img/veterans7aside.jpg'>
  <br>

  
  <ncc-app-img-viewer photosToDisplay="veterans7aside" identifier='all'></ncc-app-img-viewer>
  <ncc-app-img-upload photosToDisplay="veterans7aside" identifier='all'></ncc-app-img-upload>
  <br>

  