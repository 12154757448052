<p><b>Match Played at:</b></p>

<table class="addresstable">
    <tr>
        <td class="leftcol">
            <ul>
                <li><b>{{homeClub}}</b></li>
                <li><b>{{groundName}}</b></li>
                <li *ngFor="let a of addressLong"> {{a.long_name}}</li>
            </ul>
        </td>
        <td *ngIf="groundLat" class="rightcol"><a href="https://waze.com/ul?ll={{groundLat}},{{groundLng}}&navigate=yes"
                target="_blank">
                <img src="/assets/img/waze.png" class="waze">
            </a>
            <br>
            <a href="https://www.google.com/maps/search/?api=1&query={{groundLat}},{{groundLng}}" target="_blank">
                <img src="/assets/img/google-maps.png" class="gmaps">
            </a>
        </td>
    </tr>
</table>

<!-- Display Google Map -->
<div *ngIf="groundLat">
    <agm-map [zoom]="zoom" [draggable]="draggable" [fitBounds]="fitbounds">
        <agm-marker *ngFor='let m of markers; let i = index' [latitude]='m.lat' [longitude]='m.lng' [label]='m.label'
            [markerDraggable]='m.draggable' [iconUrl]='m.iconUrl' [agmFitBounds]="true">
            <agm-info-window [disableAutoPan]="true">
                <ul style="list-style-type:none">
                    <li *ngFor='let adrs of m.infowintxt'>{{adrs.address}}</li>
                </ul>
            </agm-info-window>
        </agm-marker>
    </agm-map>
</div>