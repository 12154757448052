import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home-page/home.component';

const homepageRoutes: Routes = [
  {path: '',  redirectTo: 'home', pathMatch: 'full' },
  {path: 'home',  component: HomeComponent }
];

@NgModule({
  imports: [RouterModule.forChild(homepageRoutes)],
  exports: [RouterModule]
})
export class HomePageRoutingModule { }
