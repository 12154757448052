import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ncc-app-navestock-privacy-statement',
  templateUrl: './navestock-privacy-statement.component.html',
  styleUrls: ['./navestock-privacy-statement.component.scss']
})
export class NavestockPrivacyStatementComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
