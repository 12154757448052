<div fxLayout="row" fxLayout.xs="column">
	<div fxFlex="1 1 66%">
		<img class='splash_img' src='assets/img/NCCCrest.png'>
		<p></p>
		<h1>Welcome to Navestock Cricket Club</h1>
		<br>
		<br>
		<img class='splash_img' src='assets/img/Navestock Shields_Low.jpg'>
		<h1>T.Rippon Mid-Essex Cricket League </h1>
		<h2>Division 4 Champions 2018 (1st XI)</h2>
		<h2>Division 9 Champions 2018 (2nd XI)</h2>
		<h2>Division 5 Champions 2017 (1st XI)</h2>
		<h2>Division 4 Champions 2002</h2>
		<br>
		<br>
		<div class='hometxt'>A typical village cricket club in the heart of the Essex countryside. Situated in the
			lovely village of Navestock, with Alec's Restaurant opposite.</div>
			<div class='hometxt'>The club has two thriving Saturday league team and a Sunday friendlies team.</div>
			<div class='hometxt'>We welcome players of all ages and abilities and would be glad to see fresh faces.
			All players and their families are most welcome and we look forward to seeing many of you at the matches.</div>
		<br>
	</div>
	<div class="endOfPage"></div>
	<div fxFlex="1 1 33%">
		<a [routerLink]="['/playerswanted']">
			<img class='players_wanted_img' src='assets/img/PlayersWantedRedv1.png'>
		</a>
		<h2>Navestock Sponsors</h2>
		<table id="wrapper">
			<tr>
			  <td><a href="https://www.blackbullfyfield.co.uk/" target="_blank">
				<img class = 'BlackBull_img' src='assets/img/BlackBullFyfieldLogo.JPG'>
			</a>
		</td>
		<td>
			<a href="https://www.agslimited.co.uk/" target="_blank">
				<img class = 'AGS_img' src='assets/img/AGS-Swirl-with-name.png'>
			</a>
		</td>
		<td>
			<a href="http://www.essexdemco.com/" target="_blank">
				<img class = 'EssexDemCo_img' src='assets/img/EssexDemCo.png'>
			</a>
		</td>
			</tr>
		  </table>
		<div>
			<ncc-app-matchwidget>Loading fixtures ...</ncc-app-matchwidget>
		</div>
		<div>
			<a class="twitter-timeline" data-height="500" data-theme="light"
				href="https://twitter.com/NavestockCC?ref_src=twsrc%5Etfw">Tweets by NavestockCC</a>
			<script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>

		</div>
	</div>
</div>