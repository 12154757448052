
  
   <div fxLayout="row" fxLayout.xs="column">
      <div fxFlex="1 1 66%">
        <p class='left_panel_heading'>Where To Find Us</p>
					<p class='left_panel_text'><b>Address:</b><br>
					The Green<br>
					Navestockside<br>
					near Brentwood Essex<br>
					CM14 5SD</p>
					
					<p class='left_panel_text'><b>Directions:</b><br>
					Go North out of Brentwood on the A128 about 2 miles out turn left in Mores Lane 
					then turn right in Snakes Hill Lane at Bentley Church, keep right in Snakes Hill Lane, then turn right in Navestock Side Lane. 
					The club is on your right opposite Alec's 
					(formerly the Green Man pub.)  If all else fails just ask for directions to the restaurant!
					</p>
					<agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom" [draggable] ="draggable">
  						<agm-marker 
							*ngFor='let m of markers; let i = index' 
								[latitude]='m.lat'
          						[longitude]='m.lng'
          						[label]='m.label'
          						[markerDraggable]='m.draggable'
								[iconUrl]='m.iconUrl'>
								<agm-info-window [disableAutoPan]="true">
         							<ul style="list-style-type:none">
										<li *ngFor='let adrs of m.infowintxt'>{{adrs.address}}</li>
									</ul>
       							</agm-info-window>
						</agm-marker>
					</agm-map>
					<br>
      </div>
      <div fxFlex="1 1 33%">
        <a [routerLink]="['/playerswanted']"><img class='players_wanted_img' src='assets/img/PlayersWantedRedv1.png'></a>
      </div>
   </div>
