<div *ngFor="let hbYear of hounoursBoard_perYear | async">
    <p class="honoursboard-year">{{hbYear.year}}</p>
    <mat-card *ngFor="let hb of hbYear.honoursBoard" [routerLink]="['/match/matchdetails/' + hb.id]">        
        <span class="honoursboard-title">{{hb.playername}}</span>
        <span class="honoursboard-title" *ngIf="hb.batsman_runs">  -  <img src='/assets/img/cricketbat.png' class="honoursboard-avatar">  {{hb.batsman_runs}}<sup *ngIf="hb.batsman_notout">*</sup></span>
        <span class="honoursboard-title" *ngIf="hb.bowler_wickets">  -  <img src='/assets/img/cricketball.png' class="honoursboard-avatar"> {{hb.bowler_wickets}} - {{hb.bowler_runs}}</span>
        <br><br>
        <span class="honoursboard-subtitle">{{hb.navestock_club_name}}: {{hb.navestock_team_name}} vs {{hb.opposition_club_name}}: {{hb.opposition_team_name}}</span>
    </mat-card>
    <br>
    <div>