import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ncc-app-veterans7aside',
  templateUrl: './veterans7aside.component.html',
  styleUrls: ['./veterans7aside.component.scss']
})
export class Veterans7asideComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
