import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'ncc-app-players-wanted',
    templateUrl: 'players-wanted.component.html',
    styleUrls: ['players-wanted.component.scss']
})

export class PlayersWantedComponent implements OnInit {
    constructor() { }

    ngOnInit() { }
}