import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ncc-app-gala-dinner',
  templateUrl: './gala-dinner.component.html',
  styleUrls: ['./gala-dinner.component.scss']
})
export class GalaDinnerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
