<div fxLayout="row" fxLayoutAlign="center start">
    <div fxFlex='70' fxFlex.lt-sm="100">
        <button *ngFor="let s of (seasonList | async )?.seasons" mat-button color="primary"
            (click)="setMatchList(s, tID)">
            {{s}}</button>

        <div *ngIf="userAuth | async; then showForm; else showLogin"></div>
        <ng-template #showForm>
            <form (ngSubmit)="onSubmit(matchListImportForm)" #matchListImportForm="ngForm">
                <mat-form-field>
                    <select matNativeControl required name="season" ngModel>
                        <option *ngFor="let ov of importSeason" value="{{ov}}">{{ov}}</option>
                    </select>
                </mat-form-field>
                <button mat-flat-button color="primary" type="submit" [disabled]="!matchListImportForm.form.valid">
                    Import Season
                </button>
            </form>
        </ng-template>

        <ng-template #showLogin>
            <h2 color="primary">** Please Login **</h2>
        </ng-template>
        <mat-card a-ng *ngFor="let mM of matchListSeason  | async" ngClass="cardMatchList">
            <mat-card-header ngClass="monthHeader">
                {{mM.monthName}} {{mM.year}}
            </mat-card-header>


            <mat-card *ngFor="let m of mM.matchArray" ngClass="cardMatch">
                <mat-card-header ngClass="matchHeader" [routerLink]="['/matchdetails-admin/' + m.id]">
                    {{m.match_date.seconds * 1000 | date}} - {{m.match_time}}
                </mat-card-header>
                <mat-card-content [routerLink]="['/matchdetails-admin/' + m.id]">
                    <div fxLayout="row" fxLayout.xs="column" fxFlex="100%" fxFlex.xs="100%" ngClass='cardContent'
                        ngClass.xs='cardContentXS'>
                        <div fxLayout="column" fxLayoutAlign="center start" fxFlex="70%" fxFlex.xs="100%">
                            <div fxLayout="row" fxFlex fxLayoutAlign="start end" ngClass='cardTeams'
                                ngClass.xs='cardTeamsXS'>{{m.opposition_club_name}} {{m.opposition_team_name}}</div>
                            <div fxLayout="row" fxFlex fxLayoutAlign="start start" ngClass='cardTeams'
                                ngClass.xs='cardTeamsXS'>{{m.navestock_club_name}} {{m.navestock_team_name}}</div>
                        </div>
                        <div fxLayout="column" fxFlex="30%" fxFlex.xs="100%">
                            <div *ngIf="!m.result_description" fxFlex ngClass='cardInfo' ngClass.xs='cardInfoXS'>
                                <b>{{m.competition_type}} match</b><br>{{m.ground_name}}</div>
                            <div *ngIf="m.result_description" fxFlex ngClass='cardInfo' ngClass.xs='cardInfoXS'>
                                <b>{{m.result_description}} match</b></div>
                        </div>
                    </div>
                </mat-card-content>
                <mat-card-actions>
                    <ng-container *ngIf="loading === false; then processingFalseBlock; else processingTrueBlock">
                    </ng-container>
                    <ng-template #processingFalseBlock>
                        <span *ngIf="m.result_last_imported !== undefined"><b>Last Imported:</b>
                            {{m.result_last_imported.toDate() | date:'d/MM/yyyy @ HH:mm:ss'}}</span>
                        <button mat-icon-button (click)="playCricketMatchDetailImport(m.id)">
                            <mat-icon svgIcon="import_export" color="primary"></mat-icon>
                        </button>
                        <button mat-icon-button>
                            <mat-icon svgIcon="delete" color="primary"></mat-icon>
                        </button>
                    </ng-template>
                    <ng-template #processingTrueBlock>
                        <br> <b>Please wait loading:</b>
                        <mat-spinner [diameter]=20></mat-spinner>
                    </ng-template>


                </mat-card-actions>
            </mat-card>
        </mat-card>

    </div>
    <div fxFlex='30' fxHide.lt-sm fxLayoutAlign="start" ƒ>
        <a [routerLink]="['/playerswanted']"><img ngClass="players_wanted_img"
                src='/assets/img/PlayersWantedRedv1.png'></a>
    </div>
</div>