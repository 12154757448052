<div fxLayout="row" fxLayout.xs="column"> 
	<div fxFlex="1 1 66%">
    	  <img class='players_wanted_img' src='./assets/img/PlayersWantedRedv1.png'>
					<p class='left_panel_text'>The club has a thriving Saturday league team and Sunday friendlies team.</p>
					<p class='left_panel_text'>We welcome players of all ages and abilities and would be glad to see fresh faces. 
					All players and their families are most welcome and we look forward to seeing many of you at the matches.</p>
					<p class='left_panel_text'>If you are interested in joining please contact.</p>
					<p class='left_panel_text'>
					<b>Chairman<br>
					Ray Balcombe</b><br>
					Tel: 01277 899141<br>
					Email:ray@technicaldramadesign.co.uk
					</p>

					<p class='left_panel_text'>
					<b>Secretary<br>
					Kevin Jordan</b><br>
					Tel: 07704 139359<br>
					Email: kevin.jordan1@btinternet.com
					</p>

					<p class='left_panel_text'>
					<b>Subscriptions:</b><br>
					Seniors £60<br>
					Students £30<br>
					Colts £40 family<br>
					</p>
					
					<p class='left_panel_text'>
					<b>Match Fees</b><br>
					Seniors £10<br>
					Students £6<br>
					Colts £2
					</p>
					
					<p class='left_panel_text'>
					<b>Outdoor Practice Nights at the club</b><br>
					Seniors Tuesday evening<br>
					Colts Wednesday evening<br>
					</p>
 
      </div>
      <div fxFlex="1 1 33%">
          
      </div>
</div>     