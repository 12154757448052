<br>
<img class='splash_img' src='assets/img/NCCCrest.png'>

<h1>Navestock Privacy Statement</h1>
<table class="grid-list">
 <tr>
   <td>
      <p><a href="https://firebasestorage.googleapis.com/v0/b/navestock-website.appspot.com/o/NavestockAdmin%2FNavestock%20Privacy%20Statement.pdf?alt=media&token=f1839839-de4e-4b3d-b39d-31af83656241">Download as PDF</a></p>
   </td>
 </tr>
  <tr>
    <td class="statementHeader">
      PRIVACY STATEMENT INTRODUCTION
    </td>
  </tr>
  <tr>
    <td class="statementText">
      <p>Navestock Cricket Club take your privacy and protection of personal data seriously. We will only use
        information gathered in relation to Navestock Cricket Club which meet the specific responsibilities as set out
        in General Data Protection Regulation and other new data protection laws coming into effect in May 2018.
        However, we will contact you with details of fixtures, events, competitions and other information regarding the
        Club. Navestock Cricket Club Secretary, Matthew Richards will store your information securely for a maximum of 1
        year should you leave the Club. This notice is to explain how we may use personal information we collect and how
        we comply with the law on data protection, as well as what your rights are.</p>
      <p>Please read the full privacy notice below carefully to see how the Club will treat the personal information
        that you provide to us.</p>
    </td>
  </tr>
</table>
<br>
<table class="grid-list">
  <tr>
    <td colspan="2" class="statementHeader">
      PRIVACY STATEMENT
    </td>
  </tr>
  <tr>
    <td class="statementLeftHeader">Names of data controller</td>
    <td class="statementText">Navestock Cricket Club</td>
  </tr>
  <tr>
    <td class="statementLeftHeader">Categories of personal data we collect</td>
    <td class="statementText">
      <ul>
        <li>Name and date of birth</li>
        <li>Contact details</li>
        <li>Medical / specific requirements information</li>
        <li>Emergency contact details</li>
        <li>Video / Photography agreement</li>
        <li>Participation agreement</li>
      </ul>
    </td>
  </tr>
  <tr>
    <td class="statementLeftHeader">Our sources of personal data</td>
    <td class="statementText">
      <p>We obtain personal data from:</p>
      <ul>
        <li>The individual registering to join the Club</li>
        <li>Club Captains and Committee</li>
        <li>Medical practitioners</li>
        <li>ECB Play-Cricket database</li>
      </ul>
  </tr>
  <tr>
    <td class="statementLeftHeader">Automated decisions we may take</td>
    <td class="statementText">None</td>
  </tr>
  <tr>
    <td class="statementLeftHeader">Purposes for which we process personal data</td>
    <td class="statementText">
      <p>The club will process the personal data for:</p>
      <ul>
        <li> Administering bookings and attendance at Club events</li>
        <li> Dealing with medical needs / specific requirements</li>
        <li> Informing Club members of Club events</li>
        <li> Arranging teams for matches</li>
      </ul>
    </td>
  </tr>
  <tr>
    <td class="statementLeftHeader">Who we will disclose your personal data to</td>
    <td class="statementText">
      <ul>
        <li>Cricket Leagues</li>
        <li>The ECB</li>
        <li>The Navestock Committee, Captains and Vice Presidents approved by the Navestock Committee</li>
        <li>The County Cricket Board that supports the local Cricket Club whose programmes you have registered for</li>
        <li>Volunteers who work at Cricket Clubs / venues to support the delivery of sessions</li>
      </ul>
    </td>
  </tr>
  <tr>
    <td class="statementLeftHeader">Legal basis for processing your personal data</td>
    <td class="statementText">
      <p>The legal basis for the collection and processing of your personal data is:</p>
      <ul>
        <li><b>For administration and programme delivery:</b> that it is necessary to fulfil the contract that you are
          going to enter into or have entered into with us</li>
        <li><b>For dealing with medical needs:</b> that you have given your explicit consent to or in your vital
          interests</li>
        <li><b>all other cases:</b> that it is necessary for our legitimate interests which are to build a programme to
          encourage participation in cricket and does not prejudice or harm your rights and freedoms</li>
      </ul>
    </td>
  </tr>
  <tr>
    <td class="statementLeftHeader">Your right to withdraw consent</td>
    <td class="statementText">
      <p>Where you have given your consent to any processing of personal data, you have the right to withdraw that
        consent at any time. If you do so, it will not affect the lawfulness of any processing for which we had consent
        prior to your withdrawing it</p>
    </td>
  </tr>
  <tr>
    <td class="statementLeftHeader">Location of your personal data</td>
    <td class="statementText">
      <p>The Club will keep your personal data within the European Economic Area</p>
    </td>
  </tr>
  <tr>
    <td class="statementLeftHeader">How long we will keep your personal data for</td>
    <td class="statementText">
      <p>
        We will not retain your personal data for longer than is reasonable and necessary for the purposes for which it
        was collected. We shall retain your personal data for such time as you are registered with Navestock Cricket
        Club as a member
        One year after you cease to be a member of Navestock Cricket Club or play an active part in said Club, we shall
        delete your data
      </p>
    </td>
  </tr>
  <tr>
    <td class="statementLeftHeader">Your rights in respect of your personal data</td>
    <td class="statementText">
      <p>You have the right of access to your personal data. In some instances, to require us to restrict, erase or
        rectify it or to object to our processing it, and the right of data portability</p>
    </td>
  </tr>
  <tr>
    <td class="statementLeftHeader">Our contact details</td>
    <td class="statementText">
      <p>If you would like to discuss any aspect of your personal data and how the club controls it, please contact Matthew
      Richards, Navestock Cricket Club Secretary</p>
      <p>Mail: 2 Staverton Road, Hornchurch, Essex RM11 2DH</p> 
      <p>Email: navestockcricket@gmail.com</p>
      <p>Phone: 07763 501394</p>
    </td>
  </tr>
  <tr>
    <td class="statementLeftHeader">Complaints</td>
    <td class="statementText"><p>If you have any concerns or complaints about how we are handling your data please do not
      hesitate to get in touch with the named person at the Club. You can also contact the Information Commissioner’s
      Office
      </p>
    </td>
  </tr>
  <tr>
    <td colspan="2">
        <p>Current as of February 2018</p>
    </td>
  </tr>
</table>
<br>