<div class="row">
      <img class='splash_img' src='/assets/img/NCCCrest.png'>
      <p></p>
      <p class='left_panel_heading'>Navestock Cricket Club</p>
      <p class='left_panel_heading'>250th Anniversary Dinner</p>
      <p class="left_panel_heading2">Monday 18th June 2018 - 7.00pm</p>
      <p class="left_panel_heading2">Old Brentwoods Club</p>
      <p class="left_panel_heading2">Ashwells Road, Essex</p>

      <img class='naveOldPic_img' src='/assets/img/navestockOldPic1.png'>
      <p class="history_text">“We hear that there will be a match of Cricket played on Saturday next, at the Green Man on Navestock Common, between the Gentlemen of Epping and the Gentlemen of Brent-wood. The wickets will be pitched at 11 o'clock and the match to be played out.”</p>
      <p class="history_text_sttribution">23rd September 1768</p>
      <img class='naveOldPic_img' src='/assets/img/navestockOldPic2.png'>
      
      <br>

      <p class="left_panel_heading3">Our Guest Speaker:</p>
      <p class="left_panel_heading3">Graham Gooch OBE</p>
      


      <p class="history_text">
          Former England and Essex Cricket Captain, Graham Gooch OBE is one of the most
          successful English batsmen of all time, scoring 67,057 runs throughout his 27-year
          career. Making his first-class debut for Essex at 19 years of age, Gooch represented his
          home county throughout his career. One of only twenty-five players to have scored
          over 100 first-class centuries, Gooch retired aged 42 after completing 118 test
          matches.
      </p>
      <img class='goochPic_img' src='/assets/img/Graham-Gooch-154.jpg'>
      <p class="history_text">
          Inducted into the International Cricket Councils (ICC) Hall of Fame in 2009, Gooch
          returned to his domestic side, Essex, to undertake a coaching role, before progressing
          on to become a batting coach with the English Cricket Board (ECB).
      </p>
      <p class="history_text">
          Holding the England captaincy from 1988 to 1993, Graham was part of the infamous
          Rebel Tour of 1990, and despite being banned for three years, his stature as a leading
          batsman remains. Having also taken over 200 first-class wickets with his medium-paced
          bowling action, Gooch is a true cricketing all-rounder.
      </p>
      <p class="history_text">
        As a cricket speaker, Graham speaks of his times at an elite level of sport as well as key
        attributes to maintain performance and achieve goals, aspects relatable to both
        business and sporting environments. A special thank you to Graham for giving up his
        time to celebrate our 250th Anniversary.
      </p>
      <br>
      <p class="left_panel_heading3">The Dinner</p>
      <img class='dinnerMenuPic_img' src='/assets/img/Navestock250DinnerMenu.png'>
      <br>
      <p class="left_panel_heading3">Photos from the evening</p>
      <ncc-app-img-viewer photosToDisplay="Nav250GalaDinner" identifier='all'></ncc-app-img-viewer>
      <br>