<div class="row">
    <img class='splash_img' src='/assets/img/NCCCrest.png'>
    <p></p>
    <p class='left_panel_heading'>Navestock Cricket Club</p>
    <p class='left_panel_heading'>Colin Bridge Villagers Cup</p>
    <p class="left_panel_heading2">Wednesday 20th June 2018</p>
  
    
    <p class="history_text">
        The Colin Bridge Villagers Cup took centre stage on the Wednesday and with five teams competing in an exciting 6-a-side tournament for the trophy. 
    </p>
    <p class="history_text">The 6 teams competing for the trophy were as follows:</p>
    <ul class="history_text">
      <li>Matching Green (holders)</li>
      <li>Navestock</li>
      <li>Abridge</li>
      <li>Fyfield</li>
      <li>Theydon Bois</li>
    </ul>

    <p class="history_text">The final was contested between Theydon Bois and Matching Green. With Matching Green winning the match with 4 balls to spare.</p>
    <p class="history_text">Congratulations to Matching Green on retaining the trophy.</p>    
        
    
    <ncc-app-img-viewer photosToDisplay="colinbridgevillagerscup" identifier='all'></ncc-app-img-viewer>
    <ncc-app-img-upload photosToDisplay="colinbridgevillagerscup" identifier='all'></ncc-app-img-upload>
    <br>